import { useSettings } from '@common/core/settings/use-settings';
import { MultiProductArticleGrid } from '@app/help-center/homepage/multi-product-article-grid';
import { Footer } from '@common/ui/footer/footer';
import React, { ReactNode, useState, useEffect } from 'react';
import { useLandingPage } from '@app/help-center/homepage/use-landing-page';
import { PageStatus } from '@common/http/page-status';
import { ArticleGrid } from '@app/help-center/homepage/article-grid';
import { ColorfulHeader } from '@app/help-center/homepage/colorful-header';
import { SimpleHeader } from '@app/help-center/homepage/simple-header';
import { DeepChat } from 'deep-chat-react';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from '@common/http/query-client';
import { useAuth } from '@common/auth/use-auth';


export function LandingPage() {
  const query = useLandingPage();
  const { landing } = useSettings();

  return (
    <Layout>
      {query.data ? (
        landing?.content?.variant === 'multiProduct' ? (
          <MultiProductArticleGrid data={query.data} />
        ) : (
          <ArticleGrid data={query.data} />
        )
      ) : (
        <PageStatus
          query={query}
          show404={false}
          delayedSpinner={false}
          loaderIsScreen={false}
        />
      )}
    </Layout>
  );
}

interface LayoutProps {
  children: ReactNode;
}


function Layout({ children }: LayoutProps) {
  const { landing } = useSettings();
  const [isLoaded, setIsLoaded] = useState(false);
  const [prompt, setPrompt] = useState<string>('');
  const { isLoggedIn, isSubscribed } = useAuth();

  useEffect(() => {
    const envPrompt = import.meta.env.VITE_PROMPT;
    if (envPrompt) {
      setPrompt(envPrompt);
      setIsLoaded(true);
    }
  }, []);

  const handleIconClick = () => {
    // Znajdź element chat-container
    const chatContainer = document.querySelector('.chat-container') as HTMLElement;
    // Sprawdź, czy element istnieje
    if (chatContainer) {
      // Zmień styl wyświetlania na block
      chatContainer.style.display = chatContainer.style.display === 'block' ? 'none' : 'block';
    }
  };

  return (
    <div className="isolate">
      {landing?.header?.variant === 'simple' ? (
        <SimpleHeader />
      ) : (
        <ColorfulHeader />
      )}
      <div className="container mx-auto mb-60 px-14 md:px-24">
        <main className="relative z-10 min-h-850">{children}</main>
      </div>
      {landing?.show_footer && <Footer className="px-40" />}
      {isLoggedIn && (
        <div className='chat-popup'>
          <div className='icon-container' onClick={handleIconClick}>
            <span className="icon">
              <svg aria-hidden="true" className="e-font-icon-svg e-far-comment" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"></path>
              </svg>
            </span>
          </div>
          <div className='chat-container' style={{ display: 'none' }}>
            <div className='chat-heading'>
              <p className='heading'>SupportMentorEngine</p>
              <p className='close-chat' onClick={handleIconClick}>X</p>
            </div>
            {isLoaded && (
              <DeepChat
                directConnection={{
                  openAI: {
                    key: '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',
                    chat: {
                      model: 'gpt-4o',
                      system_prompt: prompt,
                    }
                  }
                }}
                avatars={true}
                introMessage={{
                  "text": "Hello! I am the MentorEngine assistant. How can I help you today?"
                }}
                style={{
                  borderRadius: '10px',
                  borderColor: '#e4e4e4',
                  background: 'linear-gradient(90deg, rgb(239, 242, 247) 0%, rgb(237, 240, 249) 7.60286%, rgb(235, 239, 248) 15.2057% 20.7513%, rgb(235, 239, 248) 26.297% 27.6386%, rgb(235, 239, 248) 28.9803% 38.2826%, rgb(231, 237, 249) 47.585% 48.1216%, rgb(230, 236, 250) 48.6583% 53.1306%, rgb(228, 236, 249) 57.6029% 61.5385%, rgb(227, 234, 250) 65.4741% 68.7835%, rgb(222, 234, 250) 72.093% 75.7603%, rgb(219, 230, 248) 79.4275% 82.8265%, rgb(216, 229, 248) 86.2254% 87.8354%, rgb(213, 228, 249) 89.4454% 91.8605%, rgb(210, 226, 249) 94.2755% 95.4383%, rgb(209, 225, 248) 96.6011% 98.3005%, rgb(208, 224, 247) 100%)',
                  height: "400px"
                }}
                textInput={{
                  placeholder: {
                    text: 'Ask me anything',
                    style: {
                      color: '#606060'
                    }
                  },
                  styles: {
                    container: {
                      borderRadius: '20px',
                      border: 'unset',
                      width: '78%',
                      marginLeft: '-15px',
                      boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)'
                    },
                    text: {
                      padding: '10px',
                      paddingLeft: '15px',
                      paddingRight: '34px'
                    }
                  }
                }}
                messageStyles={{
                  default: {
                    shared: {
                      bubble: {
                        backgroundColor: "unset",
                        marginTop: "10px",
                        marginBottom: "10px",
                        boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)"
                      }
                    },
                    user: {
                      bubble: {
                        background: "linear-gradient(130deg, #2870EA 20%, #1B4AEF 77.5%)"
                      }
                    },
                    ai: {
                      bubble: {
                        background: "rgba(255,255,255,0.7)"
                      }
                    }
                  }
                }}
                submitButtonStyles={{
                  position: "outside-right",
                  submit: {
                    container: {
                      default: {
                        bottom: "0.8em",
                        borderRadius: "25px",
                        padding: "6px 5px 4px",
                        backgroundColor: "unset"
                      },
                      hover: {
                        backgroundColor: "#b0deff4f"
                      },
                      click: {
                        backgroundColor: "#b0deffb5"
                      }
                    },
                    svg: {
                      content: `<?xml version="1.0" encoding="utf-8"?> <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21.426 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.969 12 3.03 19.758a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81zM5.481 18.197l.839-3.357L12 12 6.32 9.16l-.839-3.357L18.651 12l-13.17 6.197z"/></svg>`,
                      styles: {
                        default: {
                          fill: "#4473f6",
                          width: "1.5em"
                        }
                      }
                    }
                  },
                  loading: {
                    svg: {
                      styles: {
                        default: {
                          filter: "brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(3044%) hue-rotate(322deg) brightness(100%) contrast(96%)"
                        }
                      }
                    }
                  },
                  stop: {
                    container: {
                      hover: {
                        backgroundColor: "#ededed94"
                      }
                    },
                    svg: {
                      styles: {
                        default: {
                          filter: "brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(3044%) hue-rotate(322deg) brightness(100%) contrast(96%)"
                        }
                      }
                    }
                  }
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
